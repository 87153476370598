import {
  LabelConfig,
  Mention,
  MentionType,
  RawTranscript,
  combineBlocks,
  resolveTags,
} from '@tactiq/model';
import uniq from 'lodash/uniq';
import {
  BillingStatus,
  Space,
  Tag,
  Team,
  TeamMemberStatus,
  UserDebug,
  UserOnboarding,
  UserPricing,
  UserSettingsAutosaveTimestampOption,
  UserSpace,
  UserSpaceNotifications,
} from '../../graphql/operations';
import { transformTranscript } from '../../helpers/transcript';
import {
  AnyMeeting,
  BasicMeeting,
  FullMeeting,
  TranscriptBlock,
  TransformedRawTranscript,
} from '../../models/meeting';
import { RootState } from '../store';

import { createSelector } from '@reduxjs/toolkit';
import groupBy from 'lodash/groupBy';
import uniqBy from 'lodash/uniqBy';
import { GlobalState, RateUsModalSource } from '../modules/global';

/**
 * *** IMPORTANT ***
 * Do not memoise things in this file, read https://redux.js.org/usage/deriving-data-selectors#calling-selectors-with-parameters
 */

/**
 * createSelectRawTranscriptSelector
 * @returns {RawTranscript | undefined} - RawTranscript
 */
export const createSelectRawTranscriptSelector: () => (
  state: RootState,
  meetingId: string
) => RawTranscript | undefined = () => {
  return createSelector(
    [
      (state: RootState) => state.global.rawTranscripts,
      (_state, meetingId) => meetingId,
    ],
    (rawTranscripts, meetingId) => rawTranscripts[meetingId]
  );
};

/**
 * createSelectTransformedTranscriptSelector
 * @returns {TransformedRawTranscript | undefined} - TransformedRawTranscript
 */
export const createSelectTransformedTranscriptSelector: () => (
  state: RootState,
  meetingId: string
) => TransformedRawTranscript | undefined = () => {
  return createSelector(
    [
      (state: RootState) => state.global.rawTranscripts,
      (_state, meetingId) => meetingId,
    ],
    (rawTranscripts, meetingId) =>
      rawTranscripts[meetingId]
        ? transformTranscript(rawTranscripts[meetingId] as RawTranscript)
        : undefined
  );
};

const EMPTY_BLOCKS: TranscriptBlock[] = [];

/**
 * createSelectTranscriptBlocksSelector
 * @returns {TranscriptBlock[]} - TranscriptBlock[]
 */
export const createSelectTranscriptBlocksSelector: () => (
  state: RootState,
  meetingId: string
) => TranscriptBlock[] = () => {
  const selectTransformedTranscript =
    createSelectTransformedTranscriptSelector();
  const selectMeetingTags = createSelectMeetingTagsSelector();

  return createSelector(
    [
      (state: RootState, meetingId) =>
        selectTransformedTranscript(state, meetingId),
      (state: RootState, meetingId) => selectMeetingTags(state, meetingId),
    ],
    (transcript, tags) => {
      if (!transcript) return EMPTY_BLOCKS;

      return transcript.blocks.map((block, index): TranscriptBlock => {
        return {
          ...block,
          index,
          tags: resolveTags(block.tags ?? [], tags ?? []).map((t) => ({
            ...t,
            isSystem: Boolean(t.isSystem),
          })),
        };
      });
    }
  );
};

/**
 * createSelectTranscriptHighlightsSelector
 * @returns {TranscriptBlock[]} - TranscriptBlock[]
 */
export const createSelectTranscriptHighlightsSelector: () => (
  state: RootState,
  meetingId: string
) => ReturnType<typeof combineBlocks<TranscriptBlock>> = () => {
  const selectBlocks = createSelectTranscriptBlocksSelector();

  return createSelector(
    [(state: RootState, meetingId) => selectBlocks(state, meetingId)],
    (blocks) => {
      const groups = combineBlocks(blocks);
      return groups.filter((group) => group.isPinned || group.tags?.length > 0);
    }
  );
};

/**
 * createSelectMeetingSelector
 * @returns {AnyMeeting | undefined} - AnyMeeting
 */
export const createSelectMeetingSelector: () => (
  state: RootState,
  meetingId: string
) => AnyMeeting | undefined = () => {
  return createSelector(
    [
      (state: RootState) => state.global.meetings,
      (_state, meetingId) => meetingId,
    ],
    (meetings, meetingId) => {
      if (meetings.my[meetingId]) {
        return meetings.my[meetingId];
      }

      if (meetings.sharedWithMe[meetingId]) {
        return meetings.sharedWithMe[meetingId];
      }

      if (meetings.archived[meetingId]) {
        return meetings.archived[meetingId];
      }

      for (const spaceId in meetings.spaces) {
        const space = meetings.spaces[spaceId];
        if (space && space[meetingId]) {
          return space[meetingId];
        }
      }
    }
  );
};

/**
 * selectMeetingsMonthAllowance
 * @param {RootState} state state
 * @returns {number} - Monthly allowance
 */
export const selectMeetingsMonthAllowance = (state: RootState): number => {
  return state.user.plan.free.allowance;
};
/**
 * getMeetingParticipantEmails
 * @param {FullMeeting | undefined} meeting meeting
 * @param {string} exceptEmail exceptEmail
 * @returns {string[]} - Meeting participant emails
 */
export const getMeetingParticipantEmails = (
  meeting: FullMeeting | undefined,
  exceptEmail?: string
): string[] => {
  return uniq([
    ...(meeting?.calendarData?.organizer?.email
      ? [meeting.calendarData.organizer.email]
      : []),
    ...(meeting?.calendarData?.creator?.email
      ? [meeting.calendarData.creator.email]
      : []),
    ...(meeting?.calendarData?.participants?.map(
      (participant) => participant.email
    ) ?? []),
    ...(meeting?.permissions?.allow?.emails ?? []),
  ]).filter((email) => email !== exceptEmail);
};
/**
 * Select user tags
 * @param {RootState} state state
 * @returns {Tag[]} - User tags
 */
export const selectUserTags = (state: RootState): Tag[] => {
  return state.user.settings.tags;
};

const selectUserAutoHighlightsRaw = (state: RootState) => {
  return state.user.settings.autoHighlights;
};

export const selectUserAutoHighlights = createSelector(
  [selectUserAutoHighlightsRaw],
  (autoHighlights) => {
    return groupBy(autoHighlights ?? [], 'autoTag');
  }
);
/**
 * Select Labels
 * @param {RootState} state state
 * @returns {LabelConfig[]} - Labels
 */
export const selectLabels = (state: RootState): LabelConfig[] => {
  return state.global.labels;
};
/**
 * createSelectMeetingTagsSelector
 * @returns {Tag[]} - Meeting tags
 */
export const createSelectMeetingTagsSelector: () => (
  state: RootState,
  meetingId: string
) => Tag[] | undefined = () => {
  const selectMeeting = createSelectMeetingSelector();

  return createSelector(
    [
      (state: RootState, meetingId) => selectMeeting(state, meetingId),
      (state: RootState) => state.user.id,
      selectUserTags,
      (state: RootState) => state.global.meetingTags,
    ],
    (meeting, userId, userTags, globalTags) => {
      return meeting?.userId === userId ? userTags : globalTags;
    }
  );
};
/**
 *
 * @param {RootState} state state
 * @returns {boolean} - Is meeting loading
 */
export const selectIsMeetingLoading = (state: RootState): boolean => {
  return !!state.global.operations.isLoadingMeetingData;
};
/**
 * selectUnauthorizedMeetings
 * @param {RootState} state state
 * @returns {RootState['global']['transcriptsUnauthorized']} - Unauthorized meetings
 */
export const selectUnauthorizedMeetings: (
  state: RootState
) => RootState['global']['transcriptsUnauthorized'] = (state) =>
  state.global.transcriptsUnauthorized;
/**
 * selectNotFoundMeetings
 * @param {RootState} state state
 * @returns {RootState['global']['transcriptsNotFound']} - Not found meetings
 */
export const selectNotFoundMeetings: (
  state: RootState
) => RootState['global']['transcriptsNotFound'] = (state) =>
  state.global.transcriptsNotFound;
/**
 * selectIsTranscriptUpdating
 * @param {RootState} state state
 * @returns {boolean} - Is transcript updating
 */
export const selectIsTranscriptUpdating = (state: RootState): boolean => {
  return !!state.global.operations.isUpdatingTranscript;
};
/**
 * selectIsTranscriptUpdating
 * @param {RootState} state state
 * @returns {boolean} - Is transcript updating
 */
export const selectIsAuthenticated = (state: RootState): boolean => {
  return !!state.user.id;
};
/**
 * selectUserSettings
 * @param {RootState} state state
 * @returns {RootState['user']['settings']} - User settings
 */
export const selectUserSettings = (
  state: RootState
): RootState['user']['settings'] => {
  return state.user.settings;
};
/**
 * selectUserPlan
 * @param {RootState} state state
 * @returns {RootState['user']['plan']} - User plan
 */
export const selectUserPlan = (state: RootState): RootState['user']['plan'] => {
  return state.user.plan;
};
/**
 * selectUserTeamUpgradeOption
 * @param {RootState} state state
 * @returns {RootState['user']['teamUpgradeOption']} - User team upgrade option
 */
export const selectUserTeamUpgradeOption = (
  state: RootState
): RootState['user']['teamUpgradeOption'] => {
  return state.user.teamUpgradeOption;
};
/**
 * selectUserTier
 * @param {RootState} state state
 * @returns {RootState['user']['tier']} - User tier
 */
export const selectUserTier = (state: RootState): RootState['user']['tier'] => {
  return state.user.tier;
};
/**
 * selectUserPlanCancelledAfterCurrentPeriod
 * @param {RootState} state state
 * @returns {boolean} - Is user plan cancelled after current period
 */
export const selectUserPlanCancelledAfterCurrentPeriod = (
  state: RootState
): boolean => {
  const userId = selectUid(state);
  const userPlan = selectUserPlan(state);
  const paidUserPlan = userPlan.paid;
  const team = selectTeam(state);
  const teamPlan = team?.plan;

  const isAdmin =
    team?.members.some((m) => m.uid === userId && m.roles.ADMIN) ?? false;
  const isTeam = Boolean(isAdmin && teamPlan && teamPlan.cancelAtPeriodEnd);

  const cancelAtPeriodEnd =
    ((paidUserPlan?.__typename === 'StripePaidPlan' ||
      paidUserPlan?.__typename === 'PaypalPaidPlan') &&
      paidUserPlan.isPaid &&
      paidUserPlan.cancelAtPeriodEnd) ||
    isTeam;

  if (!cancelAtPeriodEnd) {
    return false;
  }

  const teamCancelAt = isTeam && teamPlan?.cancelAt;
  const userCancelAt =
    paidUserPlan?.__typename === 'StripePaidPlan' ||
    paidUserPlan?.__typename === 'PaypalPaidPlan'
      ? paidUserPlan.cancelAt
      : false;

  const cancelAt = teamCancelAt ?? userCancelAt;

  if (cancelAt) {
    const cancellingIn = cancelAt - Date.now();
    if (cancellingIn > 1000 * 60 * 60 * 24 * 30) {
      // it's more than 30 days away
      return false;
    }
  }

  if (
    isTeam &&
    paidUserPlan?.__typename === 'StripePaidPlan' &&
    paidUserPlan.isPaid
  ) {
    // User has an active personal plan; Team has a cancelled plan
    return false;
  }

  return true;
};
/**
 * selectUserPricing
 * @param {RootState} state state
 * @returns {UserPricing} - User pricing
 */
export const selectUserPricing = (state: RootState): UserPricing => {
  return state.user.pricing;
};
/**
 * selectUid
 * @param {RootState} state state
 * @returns {string | undefined} - User id | undefined
 */
export const selectUid = (state: RootState): string | undefined => {
  return state.user.id;
};
/**
 *  selectUserEmail
 * @param {RootState} state state
 * @returns {string | undefined} - User email | undefined
 */
export const selectUserEmail = (state: RootState): string | undefined => {
  return state.user.email;
};
/**
 * selectUserName
 * @param {RootState} state state
 * @returns {string | undefined} - User name | undefined
 */
export const selectUserName = (state: RootState): string | undefined => {
  return state.user.displayName;
};
/**
 * selectUserTag
 * @param {RootState} state state
 * @returns {string | undefined} - User tag | undefined
 */
export const selectUserTag = (state: RootState): string | undefined => {
  return state.user.tag;
};
/**
 * selectUserOnboarding
 * @param {RootState} state state
 * @returns {UserOnboarding} - User onboarding
 */
export const selectUserOnboarding = (state: RootState): UserOnboarding => {
  return state.user.onboarding;
};
/**
 * selectDomainUsersCount
 * @param {RootState} state state
 * @returns {number} - Domain users count
 */
export const selectDomainUsersCount = (state: RootState): number => {
  return state.user.domainUsersCount;
};
/**
 * selectHasGoogleCalendar
 * @param {RootState} state state
 * @returns {boolean} - Is google calendar user
 */
export const selectHasGoogleCalendar = (state: RootState): boolean => {
  return state.user?.hasGoogleCalendar ?? false;
};
/**
 *
 * @param {RootState} state state
 * @returns {boolean} has workspace addon
 */
export const selectHadGoogleWorkspaceAddon = (state: RootState): boolean => {
  return state.user.hasGoogleWorkspaceAddon;
};
/**
 * selectIsProUser
 * @param {RootState} state state
 * @returns {boolean} - Is pro user
 */
export const selectIsProUser = (state: RootState): boolean => {
  return state.user.isPaid;
};
/**
 * selectIsUserPaidByTeam
 * @param {RootState} state state
 * @returns {boolean} - Is user paid by team
 */
export const selectIsUserPaidByTeam = (state: RootState): boolean => {
  return (
    state.user.isPaid && state.user.plan.paid?.__typename === 'TeamPaidPlan'
  );
};
/**
 * selectIsPreviewModeEnabled
 * @param {RootState} state state
 * @returns {boolean} - Is preview mode enabled
 */
export const selectIsPreviewModeEnabled = (state: RootState): boolean => {
  return Boolean(!selectIsProUser(state));
};
/**
 * selectLastRenewDate
 * @param {RootState} state state
 * @returns {number} - Last renew date
 */
export const selectLastRenewDate = (state: RootState): number => {
  return state.user.plan.free.lastRenewDate;
};
/**
 * selectIsUserCanSaveMeeting
 * @param {RootState} state state
 * @returns {boolean} - Can user save meeting
 */
export const selectIsUserCanSaveMeeting = (state: RootState): boolean => {
  return (
    state.user.isPaid ||
    state.user.plan.free.allowance - state.user.plan.free.used > 0
  );
};
/**
 * selectIsLoadingMeetings
 * @param {RootState} state state
 * @returns {boolean} - Is loading meetings
 */
export const selectIsLoadingMeetings = (state: RootState): boolean => {
  return state.global.operations.isLoadingMeetings ?? false;
};
/**
 * selectTeam
 * @param {RootState} state state
 * @returns {Team | undefined} - Team | undefined
 */
export const selectTeam = (state: RootState): Team | undefined =>
  state.user.team;

export const selectUserSpaces = (state: RootState): UserSpace[] | [] =>
  state.user.spaces;

export const selectAddableSpaces = createSelector(
  [selectUserSpaces],
  (spaces: UserSpace[] | undefined) =>
    spaces?.filter((s) => s.permissions?.MANAGE || s.permissions?.ADD) ?? []
);

export const selectSpaceById = createSelector(
  selectUserSpaces,
  (_, spaceId) => spaceId,
  (spaces: Space[] | undefined, spaceId) =>
    spaces?.find(({ id }) => id === spaceId)
);

export const selectCanManageSpace = (
  state: RootState,
  spaceId: string,
  userId: string
): boolean =>
  state.global.spaces[spaceId]?.members?.find((m) => m.uid === userId)
    ?.permissions.MANAGE || false;

export const selectSpaceDetailsById = createSelector(
  (state: RootState): Record<string, Space> => state.global.spaces,
  (_, spaceId) => spaceId,
  (spaces: Record<string, Space>, spaceId) => spaces[spaceId]
);

const selectTeamEmailsRaw = (state: RootState) => {
  return selectTeam(state)?.members;
};

export const selectTeamEmails = createSelector(
  [selectTeamEmailsRaw],
  (members) => {
    return members?.map(({ email }) => email) ?? [];
  }
);
/**
 * selectShowUnsavedMeetingsInList
 * @param {RootState} state state
 * @returns {boolean} - Show unsaved meetings in list
 */
export const selectShowUnsavedMeetingsInList = (state: RootState): boolean => {
  return state.user.settings?.showUnsavedMeetingsInList ?? true;
};
/**
 * selectIsSearchEnabled
 * @param {RootState} state state
 * @returns {boolean} - Is search enabled
 */
export const selectIsSearchEnabled = (state: RootState): boolean => {
  return !!state.user.settings?.searchEnabled;
};
/**
 * selectIsProcessingBilling
 * @param {RootState} state state
 * @returns {boolean} - Is processing billing
 */
export const selectIsProcessingBilling = (state: RootState): boolean => {
  return state.global.operations.isProcessingBilling ?? false;
};
/**
 * selectShowRateUsModal
 * @param {RootState} state state
 * @returns {false | RateUsModalSource} - Show rate us modal
 */
export const selectShowRateUsModal = (
  state: RootState
): false | RateUsModalSource => {
  const preChecks = Boolean(
    state.user.id &&
      state.global.userLoaded &&
      !state.user.settings?.lastRateUsModalTimestamp
  );

  return preChecks ? state.global.ui.showRateUsModalFrom : false;
};

export const selectShowCreateSpaceDialog = (state: RootState): boolean => {
  return state.global.ui.showCreateSpaceDialog;
};

/**
 * selectShowReasonForSignupModal
 * @param {RootState} state state
 * @returns {boolean} - Show reason for signup modal
 */
export const selectShowReasonForSignupModal = (state: RootState): boolean => {
  return Boolean(
    state.user.id &&
      state.global.userLoaded &&
      state.user.settings?.lastReasonForSignupModalTimestamp
  );
};

/**
 * selectIsEnhancedPrivacyEnabled
 * @param {RootState} state state
 * @returns {boolean} - Is enhanced privacy enabled
 */
export const selectIsEnhancedPrivacyEnabled = (state: RootState): boolean => {
  return !!state.user.settings?.privacy?.localFields.length;
};
/**
 * selectIsHideNamesEnabled
 * @param {RootState} state state
 * @returns {boolean} - Is hide names enabled
 */
export const selectIsHideNamesEnabled = (state: RootState): boolean => {
  return Boolean(state.user.settings?.privacy?.hideNames);
};
/**
 * selectTimestampOption
 * @param {RootState} state state
 * @returns {UserSettingsAutosaveTimestampOption} - Timestamp option
 */
export const selectTimestampOption = (
  state: RootState
): UserSettingsAutosaveTimestampOption => {
  return (
    state?.user?.settings.autoSaveOptions?.timestampOption ??
    UserSettingsAutosaveTimestampOption.DURATION
  );
};
/**
 * selectUserIntegrationConnections
 * @param {RootState} state state
 * @returns {RootState['user']['connections']} - User integration connections
 */
export const selectUserIntegrationConnections = (
  state: RootState
): RootState['user']['connections'] => {
  return state.user.connections;
};
/**
 * teamBillingPastDue
 * @param {RootState} state state
 * @returns {boolean} - Is team billing past due
 */
export const teamBillingPastDue = (state: RootState): boolean => {
  return state.user.team?.billingStatus === BillingStatus.PAST_DUE;
};
/**
 * teamBillingRenewalDisabled
 * @param {RootState} state state
 * @returns {boolean} - Is team billing renewal disabled
 */
export const teamBillingRenewalDisabled = (state: RootState): boolean => {
  return state.user.team?.billingStatus === BillingStatus.RENEWAL_DISABLED;
};
/**
 * userBillingPastDue
 * @param {RootState} state state
 * @returns {boolean} - Is user billing past due
 */
export const userBillingPastDue = (state: RootState): boolean => {
  return state.user?.billingStatus === BillingStatus.PAST_DUE;
};
/**
 * userBillingRenewalDisabled
 * @param {RootState} state state
 * @returns {boolean} - Is user billing renewal disabled
 */
export const userBillingRenewalDisabled = (state: RootState): boolean => {
  return state.user?.billingStatus === BillingStatus.RENEWAL_DISABLED;
};

export const selectAiOutputLanguage = (state: RootState): string => {
  return state.user?.settings.aiOutputLanguage;
};

export const selectMentions = createSelector(
  [
    (state: RootState) => state.global.meetings.details,
    selectUserEmail,
    selectTeam,
  ],
  (hash, userEmail, team) => {
    const allParticipants = [];
    const meetings = Object.values(hash);

    for (const meeting of meetings) {
      allParticipants.push(...getMeetingParticipantEmails(meeting, userEmail));
    }

    const displayNameCount = new Map<string, number>();

    team?.members.forEach((m) => {
      if (m.status !== TeamMemberStatus.PENDING) {
        const { displayName } = m;

        displayNameCount.set(
          displayName,
          (displayNameCount.get(displayName) ?? 0) + 1
        );
      }
    });

    return uniqBy(
      [
        ...(team?.members.map((m) => {
          if (m.status === TeamMemberStatus.PENDING) {
            return {
              type: MentionType.EMAIL,
              email: m.email,
            } as Mention;
          } else {
            let { displayName } = m;

            if ((displayNameCount.get(displayName) ?? 0) > 1) {
              displayName = `${displayName} (${m.email})`;
            }

            return {
              type: MentionType.USER,
              email: m.email,
              userId: m.uid,
              displayName,
            } as Mention;
          }
        }) ?? []),
        ...[...allParticipants].map((p) => {
          return {
            type: MentionType.EMAIL,
            email: p,
          } as Mention;
        }),
      ],
      'email'
    );
  }
);
/**
 * selectArchivedMeetings
 * @param {RootState} state state
 * @returns {BasicMeeting[]} - Archived meetings
 */
export const selectArchivedMeetings = (state: RootState): BasicMeeting[] => {
  return state.global.archivedMeetings;
};
/**
 * selectFeatureFlags
 * @param {RootState} state state
 * @returns {GlobalState['featureFlags'] | undefined} - Feature flags
 */
export const selectFeatureFlags = (
  state: RootState
): GlobalState['featureFlags'] | undefined => {
  return state.global?.featureFlags;
};

// createSelector brought in to memoize the filter which had a perf issue for redux
export const selectUsedMeetingKitsSelector = createSelector(
  [(state: RootState) => state.global.meetingKits.used],
  (used) => {
    return used.filter((k) => !k.publishedFrom);
  }
);

export const selectUserSpaceNotifications = (
  state: RootState,
  spaceId: string
): UserSpaceNotifications | undefined => {
  return state.user.spaces.find((s) => s.id === spaceId)?.notifications;
};

export const selectUserDebug = (state: RootState): UserDebug | undefined => {
  return state.user.debug;
};
