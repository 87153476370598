import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Chip } from '../../../components/Chips';
import { Switch } from '../../../components/buttons/Switch';
import { TeamTier, UserTier } from '../../../graphql/operations';
import { cx } from '../../../helpers/utils';
import { FreeTierCard } from './Tiers/FreeTierCard';
import { ProTierCard } from './Tiers/ProTierCard';
import { TeamTierCard } from './Tiers/TeamTierCard';
import { Button } from '../../../components/buttons';
import {
  KnownEvents,
  TierPricingDialogSource,
  trackWebEvent,
} from '../../../helpers/analytics';
import { selectUserPlan } from '../../../redux/selectors';
import { useSelector } from 'react-redux';

/**
 *
 * @param {unknown} param0 params
 * @param {UserTier} param0.userTier UserTier
 * @param {TeamTier | undefined} param0.teamTier Team Tier if available
 * @param {TierPricingDialogSource} param0.source Source of the dialog in the webapp
 * @param {boolean} param0.teamSpecific If the upgrade needed is specifically for team tier
 * @returns {React.ReactNode} Pricing tier card group
 */
export const TierPricingCardGroup: React.FC<{
  userTier: UserTier;
  teamTier: TeamTier | undefined;
  source: TierPricingDialogSource;
  teamSpecific?: boolean;
}> = ({ userTier, teamTier, source, teamSpecific }) => {
  const [isAnnual, setIsAnnual] = React.useState<boolean>(true);

  const isUsingPaypal = Boolean(
    useSelector(selectUserPlan).paid?.__typename === 'PaypalPaidPlan'
  );

  if (teamSpecific) {
    return (
      <div className="flex flex-col items-center justify-center gap-6 px-6">
        <div className="flex flex-row gap-2 self-start">
          <p>
            <FormattedMessage defaultMessage="Monthly" id="wYsv4Z" />
          </p>

          <Switch
            onClick={() => {
              trackWebEvent(KnownEvents.PricingWindowToggledInterval, {
                isAnnual: !isAnnual,
              });
              setIsAnnual(!isAnnual);
            }}
            isOn={isAnnual}
          />
          <p>
            <FormattedMessage defaultMessage="Yearly" id="dqD39h" />
          </p>
          <Chip color="red">
            <p>
              <FormattedMessage
                defaultMessage="Save up to {percentage}"
                id="AV1mF5"
                values={{
                  percentage: '33%',
                }}
              />
            </p>
          </Chip>
        </div>

        <div className="grid grid-cols-1 items-center justify-center gap-4 md:grid-cols-2">
          {userTier === UserTier.FREE ? <FreeTierCard /> : null}
          {userTier === UserTier.PRO ? (
            <ProTierCard
              isAnnual={isAnnual}
              userTier={userTier}
              teamTier={teamTier}
              source={source}
            />
          ) : null}
          <TeamTierCard
            isAnnual={isAnnual}
            userTier={userTier}
            teamTier={teamTier}
            source={source}
            isUsingPayPal={isUsingPaypal}
          />
        </div>
        <div className="flex items-center justify-center gap-1">
          <p className="text-sm">
            <FormattedMessage
              defaultMessage="Compare all plans and features."
              id="Y3c34S"
            />
          </p>
          <Button
            variant="text"
            size="text"
            href="https://tactiq.io/buy"
            target="_blank"
            onClick={() =>
              trackWebEvent(KnownEvents.PricingWindowExternalPricingLink)
            }
          >
            <p className="text-indigo-600 text-sm underline">
              <FormattedMessage
                defaultMessage="View pricing page"
                id="kqKojh"
              />
            </p>
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center gap-6 px-6">
      <div className="flex flex-row gap-2 self-start">
        <p>
          <FormattedMessage defaultMessage="Monthly" id="wYsv4Z" />
        </p>
        <Switch
          onClick={() => {
            trackWebEvent(KnownEvents.PricingWindowToggledInterval, {
              isAnnual: !isAnnual,
            });
            setIsAnnual(!isAnnual);
          }}
          isOn={isAnnual}
        />
        <p>
          <FormattedMessage defaultMessage="Yearly" id="dqD39h" />
        </p>
        <Chip color="red">
          <p>
            <FormattedMessage
              defaultMessage="Save up to {percentage}"
              id="AV1mF5"
              values={{
                percentage: '33%',
              }}
            />
          </p>
        </Chip>
      </div>
      <div
        className={cx(
          'grid grid-cols-1 items-center justify-center gap-4',
          userTier === UserTier.FREE
            ? 'md:grid-cols-2 lg:grid-cols-3'
            : 'md:grid-cols-2'
        )}
      >
        {userTier !== UserTier.FREE ? null : <FreeTierCard />}
        <ProTierCard
          isAnnual={isAnnual}
          userTier={userTier}
          teamTier={teamTier}
          source={source}
        />
        <TeamTierCard
          isAnnual={isAnnual}
          userTier={userTier}
          teamTier={teamTier}
          source={source}
          isUsingPayPal={isUsingPaypal}
        />
      </div>
      <div className="flex items-center justify-center gap-1">
        <p className="text-sm">
          <FormattedMessage
            defaultMessage="Compare all plans and features."
            id="Y3c34S"
          />
        </p>
        <Button
          variant="text"
          size="text"
          href="https://tactiq.io/buy"
          target="_blank"
          onClick={() =>
            trackWebEvent(KnownEvents.PricingWindowExternalPricingLink)
          }
        >
          <p className="text-indigo-600 text-sm underline">
            <FormattedMessage defaultMessage="View pricing page" id="kqKojh" />
          </p>
        </Button>
      </div>
    </div>
  );
};
