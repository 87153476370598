import { useMutation } from '@apollo/client';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Button, TLoading } from '../../components/buttons';
import { Checkbox } from '../../components/Checkbox';
import { Menu } from '../../components/Menu';
import {
  AddToSpaceDocument,
  RemoveFromSpaceDocument,
  UserSpace,
} from '../../graphql/operations';
import { trackWebEvent } from '../../helpers/analytics';
import { showCreateSpaceDialog } from '../../redux/modules/global';
import { selectAddableSpaces } from '../../redux/selectors';

type AddToSpaceMenuProps = {
  meetingId: string;
  activeSpaces: string[];
  trigger: React.ReactElement;
  onCompleted?: () => void;
};

export const AddToSpaceMenu: React.FC<AddToSpaceMenuProps> = ({
  meetingId,
  activeSpaces,
  trigger,
  onCompleted,
}) => {
  const dispatch = useDispatch();
  const addableSpaces = useSelector(selectAddableSpaces);
  const [addToSpace, addToSpaceMutation] = useMutation(AddToSpaceDocument);
  const [removeFromSpace, removeFromSpaceMutation] = useMutation(
    RemoveFromSpaceDocument
  );

  const addableSpacesSorted = [...addableSpaces].sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  const isInSpace = (spaceId: string) =>
    activeSpaces.some((id) => id === spaceId);

  const handleSpaceChange = async (space: UserSpace, checked: boolean) => {
    if (!checked) {
      await removeFromSpace({
        variables: {
          input: {
            meetingId,
            spaceId: space.id,
          },
        },
        onCompleted: () => {
          enqueueSnackbar(
            <FormattedMessage
              defaultMessage="Meeting has been removed from the space - {spaceIcon} {spaceName}"
              id="hnzejC"
              values={{
                spaceIcon: space.icon,
                spaceName: space.name,
              }}
            />,
            { variant: 'SUCCESS' }
          );
          onCompleted?.();
        },
        onError: () => {
          enqueueSnackbar(
            <FormattedMessage
              defaultMessage="Failed to remove the meeting from the space - {spaceIcon} {spaceName}"
              id="xDPeAW"
              values={{
                spaceIcon: space.icon,
                spaceName: space.name,
              }}
            />,
            { variant: 'ERROR' }
          );
        },
      });
    } else {
      await addToSpace({
        variables: {
          input: {
            meetingId,
            spaceId: space.id,
          },
        },
        onCompleted: () => {
          enqueueSnackbar(
            <FormattedMessage
              defaultMessage="Meeting has been added to the space - {spaceIcon} {spaceName}"
              id="7zw06j"
              values={{
                spaceIcon: space.icon,
                spaceName: space.name,
              }}
            />,
            { variant: 'SUCCESS' }
          );
          onCompleted?.();
        },
        onError: () => {
          enqueueSnackbar(
            <FormattedMessage
              defaultMessage="Failed to add the meeting to the space - {spaceIcon} {spaceName}"
              id="NkC75Y"
              values={{
                spaceIcon: space.icon,
                spaceName: space.name,
              }}
            />,
            { variant: 'ERROR' }
          );
        },
      });
    }
  };

  return (
    <Menu>
      <Menu.Trigger>
        {addToSpaceMutation.loading || removeFromSpaceMutation.loading ? (
          <div className="px-4">
            <TLoading />
          </div>
        ) : (
          trigger
        )}
      </Menu.Trigger>
      {addableSpacesSorted.map((space) => (
        <Menu.Item
          key={space.id}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <Checkbox
            id={space.id}
            checked={isInSpace(space.id)}
            label={
              <div>
                {space.icon} {space.name}
              </div>
            }
            onChange={async (checked) => {
              await handleSpaceChange(space, checked);
            }}
            disabled={
              addToSpaceMutation.loading || removeFromSpaceMutation.loading
            }
          />
        </Menu.Item>
      ))}
      {addableSpacesSorted.length > 0 && <Menu.Divider />}
      <Menu.Item
        key="create-space"
        onClick={(event) => {
          event.preventDefault();
        }}
      >
        <Button
          size="small"
          fullWidth
          onClick={() => {
            dispatch(showCreateSpaceDialog());
            trackWebEvent('Clicked Create Space Button', {
              source: 'meeting',
            });
          }}
        >
          <FormattedMessage defaultMessage="Create a space" id="yT9PCn" />
        </Button>
      </Menu.Item>
    </Menu>
  );
};
