import React, { FC, Fragment, ReactElement, ReactNode, useState } from 'react';
import { Combobox as Combo, ComboboxInputProps } from '@headlessui/react';
import { Check, ChevronDown, X } from 'lucide-react';
import { Button } from '../buttons';
import { cx, usePortalRoot } from '../../helpers/utils';
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  FloatingPortal,
} from '@floating-ui/react';
import { FormattedMessage } from 'react-intl';
import { Spinner } from '../Spinner';
import { useDebounce } from '../../helpers/useDebounce';

type Props<T> = {
  label?: React.ReactNode;
  options: T[];
  /** How to uniquely identify each option */
  id: (item: T) => string;
  /** A nice string representation of each option */
  name: (item: T) => string;

  icon?: ReactNode;
  renderOption?: (item: T) => ReactNode;
  renderValue?: (item: T) => ReactNode;
  /** If the onRemove callback is provided, when the Combo. has a value it will display a clear button instead of the downward chevron. */
  onRemove?: () => void;
  multiple?: boolean;
  loading?: boolean;
  hideSearch?: boolean;
  /** Keeps the selected items in the list and shows a "✔" next to them, defaults to `false` */
  keepSelected?: boolean;
  /** Higlights the combobox when it has something selected, defaults to `true` */
  highlightWhenSelected?: boolean;
  placeholder?: string;
  /** No limit on width */
  full?: boolean;
  disabled?: boolean;
  variant?: 'default' | 'naked';
  size?: 'xs' | 'sm' | 'md';
  buttonClasses?: string;

  onSearch?: (query: string) => void;
};

type SingleProps<T> = Props<T> & {
  multiple: false;
  onChange: (next: T | null) => void;
  value: T | undefined;
};

type MultiProps<T> = Props<T> & {
  multiple: true;
  value: T[];
  onChange: (next: T[]) => void;
};

/**
 * A searchable multi select
 */
export function Combobox<T = { id: string; name: string }>(
  props: SingleProps<T> | MultiProps<T>
): ReactElement {
  const {
    icon,
    label,
    options,
    name,
    id,
    renderValue,
    onRemove,
    hideSearch = false,
    keepSelected = false,
    highlightWhenSelected = true,
    full = false,
    loading = false,
    placeholder,
    disabled,
    variant = 'default',
    size = 'md',
  } = props;
  const root = usePortalRoot();
  const value = ([] as T[]).concat(props.value ?? []); // Coerce single values to an array for simpler handling
  const hasItems = value.length > 0;
  const ids = new Set(value.map((ii) => id(ii)));
  const [query, setQuery] = useState('');
  const filteredOptions = options.filter((item) =>
    keepSelected
      ? item
      : !ids.has(id(item)) &&
        (props.onSearch
          ? true
          : name(item).toLowerCase().includes(query.toLowerCase()))
  );
  const renderOption = props.renderOption ?? ((x: T) => name(x));
  const onSearch = useDebounce(() => {
    props.onSearch?.(query);
  }, 500);

  //
  // Actions
  const removeItem = (next: string) => {
    if (props.multiple) {
      props.onChange(value.filter((_) => id(_) !== next));
    } else {
      props.onChange(null);
    }
  };

  const popItem = () => props.multiple && props.onChange(value.slice(0, -1));
  const clearItems = () =>
    props.multiple ? props.onChange([]) : props.onChange(null);

  //
  // Floating Bits

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    strategy: 'fixed',
    middleware: [offset(8), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  return (
    <Combo
      value={props.value}
      onChange={props.onChange}
      disabled={disabled}
      // @ts-expect-error the headlessui types are too correct to be practical
      multiple={props.multiple}
    >
      <Combo.Button
        ref={(ref) => {
          refs.setReference(ref);
          // headlessui assumes the input is outside of the dropdown,
          // and relies on focus of that to open and close. Because our input is hidden first
          // we need this hack to keep button in the list of focusable elements.
          ref?.setAttribute('tabIndex', 'auto');
        }}
        className={cx(
          'inline-flex h-8 cursor-pointer items-center gap-2 rounded-button border border-slate-200 px-2 py-0 font-medium text-slate-700 text-sm shadow-sm',
          full ? 'max-w-full' : 'max-w-64',
          disabled
            ? 'cursor-default border-slate-200 bg-slate-100 text-slate-400'
            : hasItems && highlightWhenSelected
              ? '!text-brand-700 border-brand-300 bg-brand-25 hover:border-brand-400 hover:bg-brand-50'
              : 'hover:border-slate-300/50 hover:bg-slate-50',
          variant === 'naked' ? 'border-transparent' : '',
          `text-${size}`,
          props.buttonClasses
        )}
      >
        <div className="flex-shrink-0">{icon}</div>
        {label ? (
          <Combo.Label className="whitespace-nowrap">
            {label}
            {hasItems ? ':' : ''}
          </Combo.Label>
        ) : null}
        {hasItems && (
          <span className="truncate">
            {value.map((item) => name(item)).join(', ')}
          </span>
        )}
        <span className="ml-auto flex-shrink-0">
          {loading ? (
            <Spinner />
          ) : onRemove && hasItems ? (
            <X size="1rem" onClick={onRemove} />
          ) : (
            <ChevronDown size="1rem" />
          )}
        </span>
      </Combo.Button>
      <FloatingPortal root={root}>
        <Combo.Options
          className="z-Combobox max-h-[30vh] w-64 overflow-auto rounded-lg border bg-white p-2 shadow-slate-600/20 shadow-xl"
          ref={refs.setFloating}
          style={floatingStyles}
        >
          {!hideSearch && (
            <div className="relative mb-3 flex flex-wrap overflow-hidden rounded-md border p-1 pr-6 has-[:focus]:border-brand-400 has-[:focus]:ring has-[:focus]:ring-brand-100">
              {value.map((item) => (
                <Button
                  key={id(item)}
                  className="!font-normal focus-visible:-outline-offset-2 flex items-center truncate pr-1 text-sm"
                  variant="naked"
                  size="text"
                  endIcon={<X size=".875rem" className="ml- text-slate-400" />}
                  onClick={() => removeItem(id(item))}
                >
                  <span className="truncate">
                    {(renderValue ?? renderOption)(item)}
                  </span>
                </Button>
              ))}
              <Combo.Input
                as={FlexibleInput}
                value={query}
                placeholder={!hasItems ? placeholder : ''}
                onChange={(event) => {
                  const query = event.target.value;
                  setQuery(query);
                  onSearch();
                }}
                onKeyDown={(event) => {
                  if (event.code === 'Backspace' && query === '') popItem();
                  if (event.code === 'Enter') setQuery('');
                }}
              />
              {hasItems && (
                <button
                  className="absolute top-1.5 right-1.5 block size-4 appearance-none rounded-full bg-slate-100 p-0.5 text-white hover:bg-slate-200"
                  onClick={clearItems}
                >
                  <X size=".75rem" className="text-slate-500" />
                </button>
              )}
            </div>
          )}
          {filteredOptions.length === 0 ? (
            <span className="px-1 text-slate-500 text-sm">
              <FormattedMessage defaultMessage="No available options" />
            </span>
          ) : loading ? (
            <span className="px-1 text-slate-500 text-sm">
              <FormattedMessage defaultMessage="Loading..." />
            </span>
          ) : (
            filteredOptions.map((item) => (
              <Combo.Option key={id(item)} value={item} as={Fragment}>
                {({ active }) => (
                  <li
                    className={cx(
                      'flex cursor-pointer appearance-none items-center gap-2 overflow-hidden truncate rounded px-2 py-1 text-left text-sm',
                      active ? 'bg-slate-100' : '',
                      'disabled:cursor-default'
                    )}
                  >
                    <span className="flex-1 truncate">
                      {renderOption(item)}
                    </span>
                    {keepSelected && ids.has(id(item)) && (
                      <Check className="h-4 w-4" />
                    )}
                  </li>
                )}
              </Combo.Option>
            ))
          )}
        </Combo.Options>
      </FloatingPortal>
    </Combo>
  );
}

/**
 * This does some dodgy things with an ::after element to allow the
 * input to grow with its content and fill up the remaining space.
 */
const FlexibleInput: FC<
  Omit<ComboboxInputProps<'input', string>, 'children'>
> = (props) => {
  return (
    <div
      data-value={props.value}
      className={cx(
        'relative inline-grid flex-grow auto-cols-[auto_1fr] items-center px-1.5 leading-none',
        // :after styles
        'after:invisible after:col-start-1 after:row-start-1 after:block after:min-w-0 after:whitespace-pre-wrap after:content-[attr(data-value)]'
      )}
    >
      <input
        size={1}
        {...props}
        className={cx(
          'col-start-1 row-start-1 m-0 block min-w-0 appearance-none border-none bg-transparent p-0 text-sm outline-none'
        )}
      />
    </div>
  );
};
