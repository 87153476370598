import React from 'react';
import { cx } from '../../helpers/utils';

/**
 * A styled checkbox for boolean values
 */
export const Checkbox: React.FC<{
  checked: boolean;
  label: string | React.ReactNode;
  id: string;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  /** Optional classname applied to the wrapper div */
  className?: string;
}> = ({ checked, label, id, onChange, disabled = false, className }) => {
  return (
    <div
      className={cx(
        'flex items-center',
        disabled ? 'cursor-not-allowed' : 'cursor-pointer',
        className
      )}
    >
      <input
        disabled={disabled}
        onChange={() => onChange(!checked)}
        checked={checked}
        id={id}
        type="checkbox"
        value={checked ? 'on' : 'off'}
        className={cx(
          'h-4 w-4 flex-shrink-0 rounded border-slate-300 bg-slate-100 focus:outline-none focus:ring-2 focus:ring-brand-500',
          disabled
            ? 'cursor-not-allowed text-slate-300'
            : 'cursor-pointer text-brand-500'
        )}
      />
      <label
        htmlFor={id}
        onClick={(e) => e.stopPropagation()}
        className={cx(
          'ms-3 flex-grow text-sm',
          disabled
            ? 'cursor-not-allowed text-slate-300'
            : 'cursor-pointer text-slate-600'
        )}
      >
        {label}
      </label>
    </div>
  );
};
