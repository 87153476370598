import { useMutation } from '@apollo/client';
import { SendAutomationEventDocument } from '../graphql/operations';
import { MutationResult } from './helpers';

/**
 * Sends Brevo automation event
 */
export function useSendAutomationEvent(): MutationResult<
  typeof SendAutomationEventDocument
> {
  const [fn, { data, loading, error }] = useMutation(
    SendAutomationEventDocument
  );

  return {
    request: async (variables) => {
      try {
        return await fn({ variables: { input: variables.input } });
      } catch (e) {
        return Promise.resolve({ data: null, errors: [e] });
      }
    },
    data,
    error,
    loading,
  };
}
