import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button } from '../../../components/buttons';
import { ModalDialog } from '../../../components/modals';
import { TeamTier, UserTier } from '../../../graphql/operations';
import {
  KnownEvents,
  TierPricingDialogSource,
  trackPricingWindowViewed,
  trackWebEvent,
} from '../../../helpers/analytics';
import { RootState } from '../../../redux/store';
import { TierPricingCardGroup } from './TierPricingCard';
import { selectUserPricing } from '../../../redux/selectors';
import { getCountryCode } from '../../../helpers/locale';
import { AutomationEventName } from '@tactiq/model';
import { useSendAutomationEvent } from '../../../services/Email';

/**
 *
 * @param {TierPricingDialogSource} source Source of the dialog in the webapp
 * @param {UserTier} userTier User tier
 * @param {TeamTier | undefined} teamTier Team tier
 * @param {() => void} onClose Close dialog
 * @param {boolean} teamSpecific If the upgrade needed is specifically for team tier
 * @returns {React.ReactNode} component
 */
export const TierPricingDialog: React.FC<{
  source: TierPricingDialogSource;
  userTier: UserTier;
  teamTier: TeamTier | undefined;
  onClose: () => void;
  teamSpecific?: boolean;
}> = ({ source, userTier, teamTier, onClose, teamSpecific }) => {
  const userId = useSelector((state: RootState) => state.user.id);
  const team = useSelector((state: RootState) => state.user?.team);
  const sendAutomationEvent = useSendAutomationEvent();
  const userPricing = useSelector(selectUserPricing);
  const countryCode = getCountryCode();
  const currency = userPricing.individual.currencies?.find(
    (c) => c.countryCode === countryCode
  );

  // Seperate useEffect to fire the event to notify brevo that a user has seen the pricing window.
  React.useEffect(() => {
    sendAutomationEvent.request({
      input: { type: AutomationEventName.viewed_pricing_window_brevo_event },
    });
  }, [userId]);

  React.useEffect(() => {
    trackPricingWindowViewed(
      userId,
      team?.id,
      source,
      userTier,
      teamTier,
      currency?.currency
    );
  }, [currency?.currency, source, team, teamTier, userId, userTier]);

  function handleClose() {
    trackWebEvent(KnownEvents.PricingWindowClosed);
    onClose();
  }

  return (
    <ModalDialog
      size="large"
      title={<FormattedMessage defaultMessage="Upgrade" id="0h/lPM" />}
      open
      onClose={handleClose}
      actions={
        <Button onClick={handleClose} variant="light">
          <FormattedMessage defaultMessage="Cancel" id="47FYwb" />
        </Button>
      }
    >
      <TierPricingCardGroup
        userTier={userTier}
        teamTier={teamTier}
        source={source}
        teamSpecific={teamSpecific}
      />
    </ModalDialog>
  );
};
