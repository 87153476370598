export enum AutomationEventName {
  subscription_cancelled_personal = 'subscription_cancelled_personal',
  subscription_cancelled_team = 'subscription_cancelled_team',
  subscription_cancellation_pending_personal = 'subscription_cancellation_pending_personal',
  subscription_cancellation_pending_team = 'subscription_cancellation_pending_team',
  add_new_user_to_team = 'add_new_user_to_team', // InvitationTeamNewUser = 18,
  add_existing_user_to_team = 'add_existing_user_to_team', // InvitationTeamExistingUser = 19,
  team_joined = 'team_joined',
  subscription_user_purchases_pro_plan = 'subscription_user_purchases_pro_plan',
  subscription_user_purchases_team_plan = 'subscription_user_purchases_team_plan',
  user_has_no_ai_credits_remaining = 'user_has_no_ai_credits_remaining',
  viewed_pricing_window_brevo_event = 'viewed_pricing_window_brevo_event',
  workflow_created = 'workflow_created',
  workflow_run = 'workflow_run',
  workflow_started = 'workflow_started',
  workflow_browsed = 'workflow_browsed',
  workflow_duplicated = 'workflow_duplicated',
  workflow_saved = 'workflow_saved',
  workflow_list_viewed = 'workflow_list_viewed',
}
