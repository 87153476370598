import { LabelConfig } from '@tactiq/model';
import React, { ReactElement } from 'react';
import { createStopPropagationHandler } from '../Transcripts/common/stopPropagation';
import { X } from 'lucide-react';
import { cx } from '../../helpers/utils';

export const LabelColourPaletteClasses: Record<
  NonNullable<LabelConfig['style']['color']>,
  string
> = {
  purple:
    'bg-purple-50 ring-1 ring-inset ring-purple-700/10 hover:bg-purple-100/80 text-purple-700 ',
  blue: 'bg-blue-50 ring-1 ring-inset ring-blue-700/10 hover:bg-blue-100/80 text-blue-700',
  green:
    'bg-green-50 ring-1 ring-inset ring-green-600/20 hover:bg-green-100/80 text-green-700',
  orange:
    'bg-orange-50 ring-1 ring-inset ring-orange-700/10  hover:bg-orange-100/80 text-orange-800',
  red: 'bg-red-50 ring-1 ring-inset ring-red-600/10 hover:bg-red-100/80 text-red-700',
};

// For consistency and simplicity - These should be removed (especially the dotted)
export const LabelLineClasses: Record<
  NonNullable<LabelConfig['style']['line']>,
  string
> = {
  dashed: 'ring-[0px] border border-dashed border-slate-300 ',
  dotted: 'ring-[0px] border border-dotted border-slate-300 ',
  solid: '',
};
export const LabelVariantClasses: Record<
  NonNullable<LabelConfig['style']['variant']>,
  string
> = {
  filled: 'bg-opacity-100',
  outlined: 'bg-opacity-0',
};

export const LabelInnerWrapperClasses =
  'whitespace-nowrap overflow-hidden flex w-full';

export const LabelWrapperClasses =
  'inline-flex items-center rounded-md font-medium gap-x-1 overflow-ellipsis m-0 max-w-[200px] px-2 py-1';
/**
 * Label
 * @param {unknown} param0 params
 * @returns {React.FC} a component
 */
export const Label: React.FC<
  LabelConfig & {
    onClick?: () => void;
    onDelete?: () => void;
  }
> = ({ name, style, onClick, onDelete }) => {
  const CloseButtonClasses = 'group relative -mr-0.5 h-3 w-3 cursor-pointer';
  if (onClick) {
    return (
      <div
        className={cx(
          LabelWrapperClasses,
          style.color !== undefined
            ? LabelColourPaletteClasses[style.color]
            : LabelColourPaletteClasses.purple,
          style.line !== undefined
            ? LabelLineClasses[style.line]
            : LabelLineClasses.solid,
          style.variant !== undefined
            ? LabelVariantClasses[style.variant]
            : LabelVariantClasses.filled
        )}
      >
        <button onClick={createStopPropagationHandler(onClick)}>
          <div className={LabelInnerWrapperClasses}>
            <div className="text-nowrap font-medium text-xs leading-sm">
              {name}
            </div>
          </div>
        </button>
        {onDelete && (
          <button
            className={CloseButtonClasses}
            onClick={onDelete && createStopPropagationHandler(onDelete)}
          >
            <X className="h-3 w-3 group-hover:opacity-50" />
          </button>
        )}
      </div>
    );
  }

  return (
    <div
      className={cx(
        LabelWrapperClasses,
        style.color !== undefined
          ? LabelColourPaletteClasses[style.color]
          : LabelColourPaletteClasses.purple,
        style.line !== undefined
          ? LabelLineClasses[style.line]
          : LabelLineClasses.solid,
        style.variant !== undefined
          ? LabelVariantClasses[style.variant]
          : LabelVariantClasses.filled
      )}
    >
      <div {...style} className={LabelInnerWrapperClasses}>
        <div className="whitespace-nowrap text-xs">{name}</div>
      </div>
      {onDelete && (
        <button
          {...style}
          className={CloseButtonClasses}
          onClick={onDelete && createStopPropagationHandler(onDelete)}
        >
          <X className="h-3 w-3 group-hover:opacity-50" />
        </button>
      )}
    </div>
  );
};

export const LabelWrapper = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<{ onClick?: () => void }>
>((props, ref): ReactElement => {
  return (
    <div
      {...props}
      ref={ref}
      className={cx(
        LabelWrapperClasses,
        'bg-slate-50 text-slate-700 text-xs ring-1 ring-slate-700/10 ring-inset hover:bg-slate-100/80'
      )}
    >
      {props.children}
    </div>
  );
});

LabelWrapper.displayName = 'LabelWrapper';
