import { CheckIcon, Layers2 as ProTierIcon } from 'lucide-react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Chip } from '../../../../components/Chips';
import { Button } from '../../../../components/buttons';
import { createCheckoutSession_Personal } from '../../../../graphql/billing';
import { TeamTier, UserTier } from '../../../../graphql/operations';
import {
  TierPricingDialogSource,
  trackPricingWindowUpgrade,
} from '../../../../helpers/analytics';
import { getCountryCode } from '../../../../helpers/locale';
import { selectTeam, selectUserPricing } from '../../../../redux/selectors';
import { RootState } from '../../../../redux/store';
import {
  CurrencySymbolClasses,
  CurrentPlanComponent,
  CurrentUsageComponent,
  PriceStyleClasses,
  PricingCardWrapperClasses,
} from './CommonPieces';
import { PayPal, PayPal_Alternate } from '../../PayPal';
import { LocalPriceDialog } from '../../LocalPriceDialog';
import { cx, formatPrice } from '../../../../helpers/utils';

/**
 *
 * @param {unknown} param0 params
 * @param {boolean} param0.isAnnual isAnnual
 * @param {UserTier} param0.userTier UserTier
 * @param {TeamTier | undefined} param0.teamTier Team Tier if available
 * @param {TierPricingDialogSource} param0.source Source of the dialog
 * @returns {React.ReactNode} Pro tier card
 */
export const ProTierCard: React.FC<{
  isAnnual: boolean;
  userTier: UserTier;
  teamTier: TeamTier | undefined;
  source: TierPricingDialogSource;
}> = ({ isAnnual, userTier, teamTier, source }) => {
  const intl = useIntl();
  const userId = useSelector((state: RootState) => state.user.id);
  const team = useSelector(selectTeam);
  const [isProcessing, setIsProcessing] = React.useState<boolean>(false);
  const [localPriceDialogOpen, setLocalPriceDialogOpen] =
    React.useState<boolean>(false);
  const userPricing = useSelector(selectUserPricing);
  const countryCode = getCountryCode();
  const currency = userPricing.individual.currencies?.find(
    (c) => c.countryCode === countryCode
  );
  const pricing = React.useMemo(() => {
    if (currency) {
      return currency;
    }
    return userPricing.individual;
  }, [currency, userPricing.individual]);
  const currencySymbol =
    'currencySymbol' in pricing ? pricing.currencySymbol : pricing.currency;
  const couldReceiveLocalPricing = currency !== undefined;
  const featuresList = [
    intl.formatMessage({
      defaultMessage: 'Unlimited transcriptions',
      id: 'yYcA7M',
    }),
    intl.formatMessage({
      defaultMessage: '10 AI credits /mo',
      id: 'F9w3d9',
    }),
    intl.formatMessage({
      defaultMessage: 'Auto-Pause transcriptions',
      id: 'rioPkT',
    }),
  ];

  async function handleUpgradeClick() {
    if (!userId) return;

    trackPricingWindowUpgrade(
      userId,
      team?.id,
      source,
      isAnnual,
      userTier,
      teamTier,
      currency?.currency,
      'stripe'
    );

    if (!isProcessing) {
      try {
        setIsProcessing(true);
        window.location.href = await createCheckoutSession_Personal({
          isAnnual,
        });
      } finally {
        setIsProcessing(false);
      }
    }
  }

  const PayPalButton = couldReceiveLocalPricing ? (
    <PayPal_Alternate onClick={() => setLocalPriceDialogOpen(true)} />
  ) : (
    <PayPal
      isAnnual={isAnnual}
      source={source}
      teamTier={teamTier}
      userTier={userTier}
    />
  );

  return (
    <div className={PricingCardWrapperClasses}>
      <div className="flex flex-col gap-2 font-semibold text-2xl text-slate-700">
        <ProTierIcon className="h-7 w-7 text-sky-500" />
        <p>
          <FormattedMessage defaultMessage="Pro" id="R/eOkj" />
        </p>
      </div>
      {userTier === UserTier.PRO ? (
        <>
          <CurrentPlanComponent />
          <CurrentUsageComponent proTier />
        </>
      ) : (
        <>
          <div className="flex flex-col gap-1">
            <div className="no-wrap flex flex-row items-center gap-1">
              <p
                className={cx(
                  'font-bold',
                  CurrencySymbolClasses(
                    isAnnual
                      ? formatPrice(pricing.annualAmount / 12)
                      : pricing.monthlyAmount.toString()
                  )
                )}
              >
                {currencySymbol}
              </p>
              <p
                className={cx(
                  'font-bold',
                  PriceStyleClasses(
                    isAnnual
                      ? formatPrice(pricing.annualAmount / 12)
                      : pricing.monthlyAmount.toString()
                  )
                )}
              >
                {isAnnual
                  ? formatPrice(pricing.annualAmount / 12)
                  : pricing.monthlyAmount}
              </p>
              {isAnnual ? (
                <Chip color="red">
                  33% <FormattedMessage defaultMessage="off" id="C0MnAi" />
                </Chip>
              ) : null}
            </div>

            <p className="text-slate-600 text-sm">
              {isAnnual ? (
                <FormattedMessage
                  defaultMessage="Per month, billed annually"
                  id="F5wdzj"
                />
              ) : (
                <FormattedMessage
                  defaultMessage="Per month, billed monthly"
                  id="VFmso8"
                />
              )}
            </p>
          </div>
          <div className="flex flex-col gap-2">
            <Button
              loading={isProcessing}
              onClick={handleUpgradeClick}
              fullWidth
              variant="filled"
              size="large"
            >
              <FormattedMessage defaultMessage="Upgrade now" id="9tBhzB" />
            </Button>
            {!teamTier || teamTier === TeamTier.FREE ? PayPalButton : null}
          </div>
        </>
      )}
      <div className="flex grow flex-col justify-end gap-4">
        <p className="font-semibold text-sm">
          <FormattedMessage
            defaultMessage="For Solopreneurs & Freelancers"
            id="lZU+tF"
          />
        </p>
        <div className="flex flex-col gap-2">
          {featuresList.map((feature, index) => (
            <span
              key={`${feature}_${index}`}
              className="flex flex-row items-center gap-2 text-slate-500"
            >
              <CheckIcon />
              <p className="text-sm">{feature}</p>
            </span>
          ))}
        </div>
      </div>
      <LocalPriceDialog
        open={localPriceDialogOpen}
        onClose={() => setLocalPriceDialogOpen(false)}
        isAnnual={isAnnual}
        isProcessing={isProcessing}
        cardOnClick={handleUpgradeClick}
        source={source}
        userTier={userTier}
        teamTier={teamTier}
      />
    </div>
  );
};
