import { LabelConfig, LabelField, LabelFilter } from '@tactiq/model';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../../../../components/buttons';
import { BasicMeeting } from '../../../../../models/meeting';
import { Label } from '../../../../Common/Label';
import { ColorPicker } from './ColorPicker';
import { LabelFilterDescription } from './Description';
import { LabelFilterForm } from './FilterForm';
import { LinePicker } from './LinePicker';
import { VariantPicker } from './VariantPicker';
import { fieldDefaults } from './field-defaults';
import { TextInput } from '../../../../../components/TextInput';
import { PlusIcon } from 'lucide-react';
import { Alert } from '../../../../../components/Alert';

export const withNameError = (
  name: string,
  existingLabelNames: string[]
): React.ReactNode => {
  if (name.length < 1) {
    return (
      <FormattedMessage defaultMessage="Name cannot be empty" id="LAwI3R" />
    );
  }

  if (name.length > 50) {
    return (
      <FormattedMessage
        defaultMessage="Name must be at most 50 characters long"
        id="N2/BvL"
      />
    );
  }

  if (existingLabelNames.includes(name)) {
    return (
      <FormattedMessage
        defaultMessage="Label with such name already exists"
        id="ecIuc8"
      />
    );
  }

  return null;
};

export const LabelForm: React.FC<{
  config: LabelConfig;
  meetings: BasicMeeting[];
  existingLabelNames: string[];
  hasGoogleCalendar: boolean;
  onChange: (config: LabelConfig) => void;
}> = ({
  config,
  meetings,
  existingLabelNames,
  hasGoogleCalendar,
  onChange,
}) => {
  const nameError = withNameError(config.name, existingLabelNames);

  const onChangeName = useCallback(
    (value: string) => {
      onChange({
        ...config,
        name: value,
      });
    },
    [config, onChange]
  );

  const onChangeDescription = useCallback(
    (value: string) => {
      onChange({
        ...config,
        description: value,
      });
    },
    [config, onChange]
  );

  const onChangeFilter = useCallback(
    (filter: LabelFilter, index: number) => {
      config.filters.splice(index, 1, filter);
      onChange({
        ...config,
        filters: [...config.filters],
      });
    },
    [config, onChange]
  );

  const onDeleteFilter = useCallback(
    (index: any) => {
      config.filters.splice(index, 1);
      onChange({
        ...config,
        filters: [...config.filters],
      });
    },
    [config, onChange]
  );

  const onAddFilter = useCallback(() => {
    onChange({
      ...config,
      filters: [...config.filters, fieldDefaults[LabelField.title]],
    });
  }, [config, onChange]);

  const onChangeColor = useCallback(
    (color: any) => {
      onChange({
        ...config,
        style: {
          ...config.style,
          color,
        },
      });
    },
    [config, onChange]
  );

  const onChangeLine = useCallback(
    (line: any) => {
      onChange({
        ...config,
        style: {
          ...config.style,
          line,
        },
      });
    },
    [config, onChange]
  );

  const onChangeVariant = useCallback(
    (variant: any) => {
      onChange({
        ...config,
        style: {
          ...config.style,
          variant,
        },
      });
    },
    [config, onChange]
  );

  return (
    <div className="flex flex-col gap-y-4">
      {/* Name and description */}
      <div className="flex flex-col gap-y-2">
        <TextInput
          value={config.name}
          label={
            <FormattedMessage
              defaultMessage="Name"
              id="AVX43q"
              description="Label form. Label name field label."
            />
          }
          onChange={onChangeName}
          required
          inputProps={{
            maxLength: 50,
            minLength: 3,
          }}
          error={!!nameError}
          helperText={<span className="text-red-500 text-sm">{nameError}</span>}
        />
        <TextInput
          value={config.description}
          label={
            <FormattedMessage
              defaultMessage="Description (optional)"
              id="HFtT1W"
              description="Label form. Label description field label."
            />
          }
          onChange={onChangeDescription}
        />
      </div>

      {/* Style */}
      <div className="flex flex-col gap-2">
        <h2 className="font-semibold text-md">
          <FormattedMessage
            defaultMessage="Style"
            description="Label form. Style section title"
            id="0ERD+m"
          />
        </h2>
        <ColorPicker color={config.style.color} onChange={onChangeColor} />
        <LinePicker
          color={config.style.color}
          line={config.style.line}
          onChange={onChangeLine}
        />
        <VariantPicker
          color={config.style.color}
          variant={config.style.variant}
          onChange={onChangeVariant}
        />
      </div>

      {/* Example */}
      <div className="flex flex-col gap-y-2">
        <h2 className="font-semibold text-md">
          <FormattedMessage
            defaultMessage="Example"
            description="Label form. Label look example."
            id="y3sGKJ"
          />
        </h2>

        <Alert
          severity="info"
          variant="light"
          description={
            <div className="flex flex-col gap-y-2">
              <FormattedMessage
                defaultMessage="This is how your label will look in the UI"
                description="Label form. Label look example."
                id="JrFaH6"
              />
              <div>
                <Label {...config} />
              </div>
            </div>
          }
        />
      </div>

      {/* Filters */}
      <div className="flex flex-col gap-y-2">
        <h2 className="font-semibold text-md">
          <FormattedMessage
            defaultMessage="Filters (optional)"
            description="Label form. Filters section title"
            id="JiC2bD"
          />
        </h2>
        <LabelFilterDescription filters={config.filters} meetings={meetings} />
        {config.filters.map((filter, index) => {
          return (
            <LabelFilterForm
              key={index}
              meetings={meetings}
              filter={filter}
              hasGoogleCalendar={hasGoogleCalendar}
              onChange={(changedFilter) => onChangeFilter(changedFilter, index)}
              onDelete={() => onDeleteFilter(index)}
            />
          );
        })}
      </div>

      {/* Add filter */}
      <div>
        <Button
          size="small"
          variant="soft"
          startIcon={<PlusIcon className="h-6 w-6" />}
          onClick={onAddFilter}
        >
          <FormattedMessage
            defaultMessage="Add filter"
            description="Add label filter button label."
            id="MKJZuF"
          />
        </Button>
      </div>
    </div>
  );
};
