import debounce from 'lodash/debounce';
import { useEffect, useMemo, useRef } from 'react';

export function useDebounce<T extends () => void>(callback: T, time: number) {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  return useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(func, time);
  }, [time]);
}
