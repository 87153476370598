import {
  LabelConfig,
  LabelConfigStyleColor,
  LabelConfigStyleLine,
  LabelConfigStyleVariant,
} from '@tactiq/model';
import { ApolloClientFactory } from './client';
import {
  CreateLabelDocument,
  GetLabelsDocument,
  Label,
  RemoveLabelDocument,
  UpdateLabelDocument,
} from './operations';

const toLabel = (label: Label): LabelConfig => ({
  id: label.id,
  name: label.name,
  description: label.description,
  style: {
    color: label.style.color.toLowerCase() as LabelConfigStyleColor,
    line: label.style.line.toLowerCase() as LabelConfigStyleLine,
    variant: label.style.variant.toLowerCase() as LabelConfigStyleVariant,
  },
  filters: label.filters,
});

export const getLabels = async (): Promise<LabelConfig[]> => {
  const { data, errors } = await (await ApolloClientFactory.getClient()).query({
    fetchPolicy: 'network-only',
    query: GetLabelsDocument,
  });

  if (errors) {
    throw new Error(errors[0].message);
  }

  return data.user?.labels.map(toLabel) ?? [];
};

export const createLabel = async (
  label: Omit<LabelConfig, 'id'>
): Promise<LabelConfig | undefined> => {
  const { data, errors } = await (await ApolloClientFactory.getClient()).mutate(
    {
      mutation: CreateLabelDocument,
      variables: {
        input: {
          name: label.name,
          description: label.description,
          style: {
            color:
              label.style.color?.toUpperCase() ?? LabelConfigStyleColor.blue,
            line: label.style.line?.toUpperCase() ?? LabelConfigStyleLine.solid,
            variant:
              label.style.variant?.toUpperCase() ??
              LabelConfigStyleVariant.outlined,
          },
          filters: label.filters,
        },
      },
    }
  );

  if (errors) {
    throw new Error(errors[0].message);
  }

  return data?.createLabel?.label ? toLabel(data.createLabel.label) : undefined;
};

export const updateLabel = async (label: LabelConfig): Promise<void> => {
  const { errors } = await (await ApolloClientFactory.getClient()).mutate({
    mutation: UpdateLabelDocument,
    variables: {
      input: {
        id: label.id,
        name: label.name,
        description: label.description,
        style: {
          color: label.style.color?.toUpperCase() ?? LabelConfigStyleColor.blue,
          line: label.style.line?.toUpperCase() ?? LabelConfigStyleLine.solid,
          variant:
            label.style.variant?.toUpperCase() ??
            LabelConfigStyleVariant.outlined,
        },
        filters: label.filters,
      },
    },
  });

  if (errors) {
    throw new Error(errors[0].message);
  }
};

export const removeLabel = async (id: string): Promise<void> => {
  const { errors } = await (await ApolloClientFactory.getClient()).mutate({
    mutation: RemoveLabelDocument,
    variables: {
      input: id,
    },
  });

  if (errors) {
    throw new Error(errors[0].message);
  }
};
