import { RawTranscript } from '@tactiq/model';
import { omitDeep } from 'lodash-omitdeep';
import { ApolloClientFactory } from './client';
import {
  AddMeetingLabelDocument,
  ArchiveMeetingDocument,
  AttachMeetingRecordingDocument,
  DeleteMeetingDocument,
  MeetingFullFieldsWithTranscriptFragment,
  MeetingUpdatesDocument,
  MeetingWithTranscriptDocument,
  RemoveMeetingLabelDocument,
  RestoreArchivedMeetingDocument,
  TrackMeetingViewDocument,
  TranscriptUpdatesDocument,
  UpdateMeetingDocument,
  UpdateMeetingInput,
  MeetingsDocument,
  MeetingsSubscription,
} from './operations';
import { FullMeeting } from '../models/meeting';

export const subscribeToMeetingsUpdates = (
  client: (typeof ApolloClientFactory)['_client'],
  onNext: (meetings: MeetingsSubscription['meetings']) => void,
  onComplete: () => void,
  onError: (err: Error) => void
): (() => void) => {
  const observable = client.subscribe({
    query: MeetingsDocument,
  });

  const subscription = observable.subscribe({
    next: ({ data, errors }) => {
      if (errors || !data?.meetings) {
        onError(
          errors?.[0].message
            ? new Error(errors?.[0].message)
            : new Error('Unexpected response')
        );
      } else {
        onNext(data.meetings);
      }
    },
    error: onError,
    complete: onComplete,
  });

  return () => subscription.unsubscribe();
};

export const subscribeToMeetingUpdates = (
  client: (typeof ApolloClientFactory)['_client'],
  meetingId: string,
  onNext: (meeting: FullMeeting) => void,
  onComplete: () => void,
  onError: (err: Error) => void
): (() => void) => {
  const observable = client.subscribe({
    query: MeetingUpdatesDocument,
    variables: { id: meetingId },
  });

  const subscription = observable.subscribe({
    next: ({ data, errors }) => {
      if (errors || !data?.meeting) {
        onError(
          errors?.[0].message
            ? new Error(errors?.[0].message)
            : new Error('Unexpected response')
        );
      } else {
        onNext(data.meeting);
      }
    },
    error: onError,
    complete: onComplete,
  });

  return () => subscription.unsubscribe();
};

export const subscribeToTranscriptUpdates = (
  client: (typeof ApolloClientFactory)['_client'],
  meetingId: string,
  onNext: (transcript: RawTranscript) => void,
  onComplete: () => void,
  onError: (err: Error) => void
): (() => void) => {
  const observable = client.subscribe({
    query: TranscriptUpdatesDocument,
    variables: {
      id: meetingId,
    },
  });

  const subscription = observable.subscribe({
    next: ({ data, errors }) => {
      if (errors || !data?.transcript?.transcript) {
        onError(
          errors?.[0].message
            ? new Error(errors?.[0].message)
            : new Error('Unexpected response')
        );
      } else {
        onNext(data.transcript.transcript);
      }
    },
    error: onError,
    complete: onComplete,
  });

  return () => subscription.unsubscribe();
};

export const queryMeetingWithTranscript = async (
  meetingId: string
): Promise<MeetingFullFieldsWithTranscriptFragment> => {
  const { data, errors } = await (await ApolloClientFactory.getClient()).query({
    fetchPolicy: 'network-only',
    query: MeetingWithTranscriptDocument,
    variables: {
      meetingId,
    },
  });

  if (errors) {
    throw new Error(errors[0].message);
  }

  if (!data.meeting) {
    throw new Error('Unexpected GraphQL response');
  }

  return data.meeting;
};

export const updateMeeting = async (
  input: UpdateMeetingInput
): Promise<void> => {
  const { errors, data } = await (await ApolloClientFactory.getClient()).mutate(
    {
      mutation: UpdateMeetingDocument,
      variables: {
        input: omitDeep(input, '__typename'),
      },
    }
  );

  if (errors?.length) {
    throw new Error(errors[0].message);
  }

  if (!data?.updateMeeting?.success) {
    throw new Error(
      data?.updateMeeting?.errors?.[0].message ?? 'Unknown error'
    );
  }
};

export const addMeetingLabel = async (
  meetingId: string,
  labelId: string
): Promise<void> => {
  const { errors, data } = await (await ApolloClientFactory.getClient()).mutate(
    {
      mutation: AddMeetingLabelDocument,
      variables: {
        meetingId,
        labelId,
      },
    }
  );

  if (errors?.length) {
    throw new Error(errors[0].message);
  }

  if (!data?.addMeetingLabel?.success) {
    throw new Error('Unexpected GraphQL response');
  }
};

export const removeMeetingLabel = async (
  meetingId: string,
  labelId: string
): Promise<void> => {
  const { errors, data } = await (await ApolloClientFactory.getClient()).mutate(
    {
      mutation: RemoveMeetingLabelDocument,
      variables: {
        meetingId,
        labelId,
      },
    }
  );

  if (errors?.length) {
    throw new Error(errors[0].message);
  }

  if (!data?.removeMeetingLabel?.success) {
    throw new Error('Unexpected GraphQL response');
  }
};

export const trackMeetingView = async (meetingId: string): Promise<void> => {
  const { errors, data } = await (await ApolloClientFactory.getClient()).mutate(
    {
      mutation: TrackMeetingViewDocument,
      variables: {
        id: meetingId,
      },
    }
  );

  if (errors?.length) {
    throw new Error(errors[0].message);
  }

  if (!data?.trackMeetingView?.success) {
    throw new Error('Unexpected GraphQL response');
  }
};

export const attachMeetingRecording = async (
  meetingId: string,
  link: string
): Promise<void> => {
  const { errors, data } = await (await ApolloClientFactory.getClient()).mutate(
    {
      mutation: AttachMeetingRecordingDocument,
      variables: {
        input: {
          id: meetingId,
          link,
        },
      },
    }
  );

  if (errors?.length) {
    throw new Error(errors[0].message);
  }

  if (!data?.attachMeetingRecording?.success) {
    throw new Error('Unexpected GraphQL response');
  }
};

export const archiveMeeting = async (meetingId: string): Promise<void> => {
  const { errors, data } = await (await ApolloClientFactory.getClient()).mutate(
    {
      mutation: ArchiveMeetingDocument,
      variables: {
        input: {
          id: meetingId,
        },
      },
    }
  );

  if (errors?.length) {
    throw new Error(errors[0].message);
  }

  if (!data?.archiveMeeting?.success) {
    throw new Error('Unexpected GraphQL response');
  }
};

export const deleteMeeting = async (meetingId: string): Promise<void> => {
  const { errors, data } = await (await ApolloClientFactory.getClient()).mutate(
    {
      mutation: DeleteMeetingDocument,
      variables: {
        input: {
          id: meetingId,
        },
      },
    }
  );

  if (errors) {
    throw new Error(errors[0].message);
  }

  if (!data?.deleteMeeting?.success) {
    throw new Error('Unexpected GraphQL response');
  }
};

export const restoreArchivedMeeting = async (
  meetingId: string
): Promise<void> => {
  const { errors, data } = await (await ApolloClientFactory.getClient()).mutate(
    {
      mutation: RestoreArchivedMeetingDocument,
      variables: {
        input: {
          id: meetingId,
        },
      },
    }
  );

  if (errors?.length) {
    throw new Error(errors[0].message);
  }

  if (!data?.restoreMeeting?.success) {
    throw new Error('Unexpected GraphQL response');
  }
};
