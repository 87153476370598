import { getRewardfulReferral } from '../helpers/rewardful';
import { ApolloClientFactory } from './client';
import {
  ApplyCancellationCouponDocument,
  ApplyCancellationCouponMutationVariables,
  CancelSubscriptionDocument,
  CreateCheckoutSession_PersonalDocument,
  CreateCheckoutSession_Personal_Input,
  CreateCheckoutSession_Personal_SwitchToPayByCardDocument,
  CreateCheckoutSession_SwitchToPayByCard_Input,
  CreateCheckoutSession_TeamDocument,
  CreateCheckoutSession_Team_Input,
  CreateStripePortalLinkDocument,
  ResumeSubscriptionDocument,
} from './operations';

export const createStripePortalLink = async (): Promise<string> => {
  const { data, errors } = await (await ApolloClientFactory.getClient()).mutate(
    {
      mutation: CreateStripePortalLinkDocument,
    }
  );

  if (errors) {
    throw new Error(errors[0].message);
  }

  if (!data?.payment_createStripePortalLink?.url) {
    throw new Error('Unexpected mutation response');
  }

  return data.payment_createStripePortalLink.url;
};

export const cancelSubscription = async (
  reason: string,
  reasonText: string
): Promise<void> => {
  const { errors } = await (await ApolloClientFactory.getClient()).mutate({
    mutation: CancelSubscriptionDocument,
    variables: {
      input: {
        reason,
        reasonText,
      },
    },
  });

  if (errors?.length) {
    throw new Error(errors[0].message);
  }
};

export const applyCancellationCoupon = async (
  input: ApplyCancellationCouponMutationVariables['input']
): Promise<void> => {
  const { errors } = await (await ApolloClientFactory.getClient()).mutate({
    mutation: ApplyCancellationCouponDocument,
    variables: {
      input,
    },
  });

  if (errors?.length) {
    throw new Error(errors[0].message);
  }
};

export const resumeSubscription = async (): Promise<void> => {
  const { errors } = await (await ApolloClientFactory.getClient()).mutate({
    mutation: ResumeSubscriptionDocument,
  });

  if (errors?.length) {
    throw new Error(errors[0].message);
  }
};

export const createCheckoutSession_Personal = async (
  input: CreateCheckoutSession_Personal_Input
): Promise<string> => {
  const referral = await getRewardfulReferral();

  const { data, errors } = await (await ApolloClientFactory.getClient()).mutate(
    {
      mutation: CreateCheckoutSession_PersonalDocument,
      variables: {
        input: {
          ...input,
          clientReferenceId: referral,
          redirectToLocalhost: isLocalhost(),
        },
      },
    }
  );

  if (errors) {
    throw new Error(errors[0].message);
  }

  if (!data?.createCheckoutSession_Personal?.url) {
    throw new Error('Unexpected mutation response');
  }

  return data.createCheckoutSession_Personal.url;
};

export const createCheckoutSession_Personal_SwitchToPayByCard = async (
  input: CreateCheckoutSession_SwitchToPayByCard_Input
): Promise<string> => {
  const res = await (await ApolloClientFactory.getClient()).mutate({
    mutation: CreateCheckoutSession_Personal_SwitchToPayByCardDocument,
    variables: {
      input: {
        ...input,
        redirectToLocalhost: isLocalhost(),
      },
    },
  });

  const { errors } = res;

  if (errors?.length) {
    throw new Error(errors[0].message);
  }

  if (!res.data?.createCheckoutSession_Personal_SwitchToPayByCard?.url) {
    throw new Error('Unexpected mutation response');
  }

  return res.data?.createCheckoutSession_Personal_SwitchToPayByCard?.url;
};

export const createCheckoutSession_Team = async (
  input: CreateCheckoutSession_Team_Input
): Promise<string> => {
  const referral = await getRewardfulReferral();

  const { data, errors } = await (await ApolloClientFactory.getClient()).mutate(
    {
      mutation: CreateCheckoutSession_TeamDocument,
      variables: {
        input: {
          ...input,
          clientReferenceId: referral,
          redirectToLocalhost: isLocalhost(),
        },
      },
    }
  );

  if (errors) {
    throw new Error(errors[0].message);
  }

  if (!data?.createCheckoutSession_Team?.url) {
    throw new Error('Unexpected mutation response');
  }

  return data.createCheckoutSession_Team.url;
};

export function isLocalhost(): boolean {
  return window.location.hostname === 'localhost';
}
