import React, { PropsWithChildren } from 'react';
import { tv } from 'tailwind-variants';

export type ChipColor = 'green' | 'red' | 'yellow' | 'slate';

const chipClasses = tv({
  slots: {
    main: 'inline-flex items-center justify-center gap-0.5 whitespace-nowrap px-2 py-0.5 font-medium text-xs',
    button: 'group -mr-1 relative h-3.5 w-3.5 rounded-sm',
    icon: 'h-3.5 w-3.5',
  },
  variants: {
    type: {
      pill: {
        main: 'rounded-2xl px-3 text-white',
        button: 'rounded-2xl hover:bg-black/20',
        icon: 'stroke-white',
      },
      tag: {
        main: 'rounded-md border',
      },
      dot: {
        main: 'size-2 rounded-full p-0',
      },
    },
    color: {
      red: {},
      green: {},
      yellow: {},
      slate: {},
    },
  },
  compoundVariants: [
    // Pill Colors
    { type: ['pill', 'dot'], color: 'red', class: { main: 'bg-red-600' } },
    { type: ['pill', 'dot'], color: 'green', class: { main: 'bg-green-600' } },
    {
      type: ['pill', 'dot'],
      color: 'yellow',
      class: { main: 'bg-yellow-500' },
    },
    { type: ['pill', 'dot'], color: 'slate', class: { main: 'bg-slate-700' } },

    // Tag Colors
    {
      type: 'tag',
      color: 'red',
      class: {
        main: 'border-red-600/10 bg-red-50 text-red-700',
        button: 'hover:bg-red-600/20',
        icon: 'stroke-red-700/50 group-hover:stroke-red-700/75',
      },
    },
    {
      type: 'tag',
      color: 'green',
      class: {
        main: 'border-green-600/20 bg-green-50 text-green-700',
        button: 'hover:bg-green-600/20',
        icon: 'stroke-green-700/50 group-hover:stroke-green-700/75',
      },
    },
    {
      type: 'tag',
      color: 'yellow',
      class: {
        main: 'border-yellow-600/20 bg-yellow-50 text-yellow-700',
        button: 'hover:bg-yellow-600/20',
        icon: 'stroke-yellow-700/50 group-hover:stroke-yellow-700/75',
      },
    },
    {
      type: 'tag',
      color: 'slate',
      class: {
        main: 'border-slate-200 bg-white text-slate-500',
        button: 'hover:bg-slate-600/20',
        icon: 'stroke-slate-700/50 group-hover:stroke-slate-700/75',
      },
    },
  ],
});

export type ChipClickHandler = (() => void) | (() => Promise<void>);

/**
 * Tiny little badge for showing things like tags and labels
 */
export const Chip: React.FC<
  PropsWithChildren<{
    color?: ChipColor;
    type?: 'tag' | 'pill' | 'dot';
    onClick?: ChipClickHandler;
    onDelete?: ChipClickHandler;
    disabled?: boolean;
    className?: string;
  }>
> = ({
  children,
  color = 'green',
  type = 'tag',
  onDelete,
  onClick,
  disabled,
  className,
}) => {
  const classes = chipClasses({ color, type });
  return (
    <span
      className={classes.main({ className })}
      onClick={disabled ? undefined : onClick}
    >
      {children}
      {onDelete ? (
        <button
          type="button"
          className={classes.button()}
          onClick={disabled ? undefined : onDelete}
        >
          <span className="sr-only">Remove</span>
          <svg viewBox="0 0 14 14" className={classes.icon()}>
            <path d="M4 4l6 6m0-6l-6 6" />
          </svg>
          <span className="-inset-1 absolute" />
        </button>
      ) : null}
    </span>
  );
};
